.smartbanner-link {
  margin-right: 6px;
  font-size: large;
  text-decoration: none;
}

.smartbanner-link-light {
  color: #0000ee;
}

.smartbanner-link-dark {
  color: whitesmoke;
}

.smartbanner-close-button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 6px;
}

.smartbanner-close-button:hover {
  background-color: gray;
  border-radius: 20%;
}

.smartbanner-img {
  max-height: 64px;
  max-width: 64px;
  border-radius: 20%;
  padding-left: 4px;
}

.smartbanner {
  display: flex;
  flex-direction: row;
  min-width: 0;
  max-width: 100vw;
  min-height: 65px;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}

.smartbanner-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.smartbanner-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
}

.smartbanner-light {
  color: grey;
  background: whitesmoke;
}

.smartbanner-dark {
  background: rgb(31, 31, 31);
  color: rgb(191, 191, 191);
}

.smartbanner-title {
  font-size: large;
  margin-top: 0;
  margin-bottom: 3px;
}

.smartbanner-title-light {
  color: black;
}

.smartbanner-title-dark {
  color: whitesmoke;
}

.smartbanner-text {
  margin-top: 0;
  margin-bottom: 0;
}

.smartbanner-space-right {
  margin-right: 10px;
}

.smartbanner-space-left {
  margin-left: 10px;
}

.smartbanner-small-font {
  font-size: small;
}

.smartbanner-medium-font {
  font-size: medium;
}
