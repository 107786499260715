/* styles.css */
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

em {
  font-style: italic;
}

a {
  color: blue;
  text-decoration: underline;
}

blockquote {
  margin: 1em 0;
  padding: 0.5em 10px;
  border-left: 5px solid #ccc;
  color: #666;
  background-color: #f9f9f9;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

code {
  font-family: monospace;
}
